<!-- Gestión de Tipos de Documentos -->

<template>
  <div class="ges_tipoDocs">
    <v-sheet elevation="4">
      <!-- Cabecera -->
      <div class="cabecera">
        <base_Header
          v-if="Entorno_F.header.header"
          :Entorno="Entorno_F.header"
          @onEvent="event_capture"
        >
        </base_Header>
      </div>

      <!-- Contenido -->
      <div class="contenido pt-5">
        <v-row no-gutters>
          <!-- Selección del Origen -->
          <v-col md="4">
            <v-sheet class="pl-5" :elevation="4">
              <v-list>
                <v-list-item-group v-model="origenes" active-class="blue--text">
                  <template
                    v-for="(origen, index) in $store.state.datos_iniciales
                      .tipo_docum"
                  >
                    <v-list-item :key="origen.id" dense>
                      <template v-slot:default="{}">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="origen.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="
                        index + 1 <
                          $store.state.datos_iniciales.tipo_docum.length
                      "
                      :key="origen.name"
                    >
                    </v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-sheet>
          </v-col>

          <!-- Selección de los Tipos de Documentos -->
          <v-col md="8" class="pl-5">
            <v-sheet class="pl-5" :elevation="4">
              <!-- Botones acciones -->
              <v-btn
                fab
                x-small
                color="#4f7d94"
                title="Cancelar"
                @click="cancelar_seleccion()"
              >
                <v-icon small color="white">mdi-close-circle</v-icon>
              </v-btn>

              <v-btn
                class="ml-2"
                fab
                x-small
                color="#4f7d94"
                title="Guardar"
                @click="guardar_seleccion()"
              >
                <v-icon small color="white">mdi-content-save</v-icon>
              </v-btn>

              <!-- Lista de Documentos -->
              <v-list>
                <v-list-item-group
                  v-model="selections"
                  active-class="pink--text"
                  multiple
                >
                  <template
                    v-for="(tipo, index) in $store.state.datos_iniciales
                      .tipo_documento"
                  >
                    <v-list-item :key="tipo.id" dense>
                      <template v-slot:default="{}">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="tipo.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="
                        index + 1 <
                          $store.state.datos_iniciales.tipo_documento.length
                      "
                      :key="tipo.name"
                    >
                    </v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import plugs from "@/common/general_plugs";
const base_Header = () => plugs.groute("base_Header.vue", "base");

export default {
  components: { base_Header },
  props: {
    Entorno_F: {
      type: Object,
      default: function() {
        return JSON.parse(JSON.stringify(this.$cfg.base.F));
      }
    }
  },

  data() {
    return {
      origenes: undefined,
      read_selections: [],
      selections: []
    };
  },

  created() {
    this.get_entorno();
  },

  methods: {
    // gestor de eventos
    event_capture(evt) {
      console.log("*** onEvent ges_tipoDocs.vue. Accion: ", evt, " ***");

      this.$emit("onEvent", evt);
    },

    // entorno particular del componente
    get_entorno() {
      this.Entorno_F.header.titulo = "Asignación Tipos de Documentos";
      this.Entorno_F.header.style += ";min-width:60rem";
    },

    // selecciono el módulo de origen
    async selecciono_origen() {
      this.selections = [];

      // salgo si hemos Deseleccionado el origen
      if (this.origenes === undefined) return;

      // recorro todos los tipos de documentos y voy guardando los que están seleccionados
      // para el módulo de origen indicado
      var tpd = await this.$store.getters.get_dato_inicial("tipo_documento");
      for (var i = 0; i < tpd.length; i++) {
        if (tpd[i].modulos.charAt(this.origenes + 1) === "1")
          this.selections.push(i);
      }

      // guardo copia de las selecciones
      this.read_selections = JSON.parse(JSON.stringify(this.selections));
    },

    // guardo los documentos seleccionados según su módulo de origen
    async guardar_seleccion() {
      if (this.origenes === undefined)
        return this.$root.$alert.open("Debes seleccionar un origen", "error");

      var modulos_a_guardar = [];
      var tpd = this.$store.state.datos_iniciales.tipo_documento;
      var moduloIni;
      var moduloFin;

      for (var i = 0; i < tpd.length; i++) {
        // paso cadena de módulos a array
        moduloIni = tpd[i].modulos.split("");

        // modifico según el origen seleccionado si está activado o no
        moduloIni[this.origenes + 1] =
          this.selections.indexOf(i) >= 0 ? "1" : "0";

        // paso array a string
        moduloFin = moduloIni.join("");

        // guardo en store
        tpd[i].modulos = moduloFin;

        // guardo en variable para actualizar bd
        modulos_a_guardar.push({ id: tpd[i].id, modulos: moduloFin });
      }

      // actualizo bd
      var args = {
        tipo: "fnc",
        api: "documentos_tpd_modulos",
        fn_args: { modulos: modulos_a_guardar }
      };
      var r = await this.$store.dispatch("ajaxRequest", { args: args });
      this.guardar_seleccion_fin(r);
    },

    guardar_seleccion_fin(r) {
      if (r.error)
        return this.$root.$alert.open("Error al guardar la selección", "error");

      this.$root.$alert.open("Guardado correctamente", "success", 1800);
      this.read_selections = JSON.parse(JSON.stringify(this.selections));
    },

    // cancelo la selección de los documentos modificados
    cancelar_seleccion() {
      this.selections = JSON.parse(JSON.stringify(this.read_selections));
    }
  },

  watch: {
    origenes() {
      this.selecciono_origen();
    }
  }
};
</script>
