var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ges_tipoDocs"},[_c('v-sheet',{attrs:{"elevation":"4"}},[_c('div',{staticClass:"cabecera"},[(_vm.Entorno_F.header.header)?_c('base_Header',{attrs:{"Entorno":_vm.Entorno_F.header},on:{"onEvent":_vm.event_capture}}):_vm._e()],1),_c('div',{staticClass:"contenido pt-5"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"4"}},[_c('v-sheet',{staticClass:"pl-5",attrs:{"elevation":4}},[_c('v-list',[_c('v-list-item-group',{attrs:{"active-class":"blue--text"},model:{value:(_vm.origenes),callback:function ($$v) {_vm.origenes=$$v},expression:"origenes"}},[_vm._l((_vm.$store.state.datos_iniciales
                    .tipo_docum),function(origen,index){return [_c('v-list-item',{key:origen.id,attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(origen.name)}})],1)]}}],null,true)}),(
                      index + 1 <
                        _vm.$store.state.datos_iniciales.tipo_docum.length
                    )?_c('v-divider',{key:origen.name}):_vm._e()]})],2)],1)],1)],1),_c('v-col',{staticClass:"pl-5",attrs:{"md":"8"}},[_c('v-sheet',{staticClass:"pl-5",attrs:{"elevation":4}},[_c('v-btn',{attrs:{"fab":"","x-small":"","color":"#4f7d94","title":"Cancelar"},on:{"click":function($event){return _vm.cancelar_seleccion()}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-close-circle")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","color":"#4f7d94","title":"Guardar"},on:{"click":function($event){return _vm.guardar_seleccion()}}},[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-content-save")])],1),_c('v-list',[_c('v-list-item-group',{attrs:{"active-class":"pink--text","multiple":""},model:{value:(_vm.selections),callback:function ($$v) {_vm.selections=$$v},expression:"selections"}},[_vm._l((_vm.$store.state.datos_iniciales
                    .tipo_documento),function(tipo,index){return [_c('v-list-item',{key:tipo.id,attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(tipo.name)}})],1)]}}],null,true)}),(
                      index + 1 <
                        _vm.$store.state.datos_iniciales.tipo_documento.length
                    )?_c('v-divider',{key:tipo.name}):_vm._e()]})],2)],1)],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }